<template>
    <el-dialog class="special-edit" :visible="viewIsReady" @close="handleClose" :title="titleText" width="730px">
        <el-form class="private" :model="PB" :rules="rules" label-width="160px" label-suffix=":" ref="form" @submit.prevent="handleSaveBefore">
            <el-form-item label="特殊申请类型" prop="confid">
                <el-select v-model="PB.confid" class="width3">
                    <el-option v-for="e in specialList" :key="e.id" :label="e.name" :value="e.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请有效期开始日期" prop="starttime">
                <el-date-picker class="width3" v-model="PB.starttime" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="申请有效期结束日期" prop="endtime">
                <el-date-picker class="width3" v-model="PB.endtime" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="特殊申请附件" prop="imgPath" class="filefrom">
                <div v-if="pdfList" class='flex'>
                    <div @click="handfile(item)" class="imgItem" v-for="(item,index) in pdfList" :key="index">
                        <img class="inlineblock img c-pointer " src="../../assets/images/pdfview.png">
                        <i class="el-icon-delete del" @click.stop="delBackFile(index)"></i>
                    </div>
                </div>
                <viewer :images="fileList">
                    <div class="flex">
                        <div class="imgItem" v-for="(item,index) in fileList" :key="index">
                            <img class="inlineblock img c-pointer " :src="item.url">
                            <i class="el-icon-delete del" @click="delBackPic(index)"></i>
                        </div>
                    </div>
                </viewer>
                <el-upload :data='needzip' v-show="(fileList.length+pdfList.length)<=4" class="avatar-uploader" :action="picUrl" :show-file-list="false" accept=".jpg,.jpeg,.png,.pdf" :on-success="handleBackImgSuccess" :before-upload="handleBeforeUpload" :on-progress="handleProgress">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="el-upload__tip">附件类型：图片或pdf,大小小于5M，最多只能上传5个附件</div>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSaveBefore" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "SpecialApplication",
    components: {},
    props: ["item", "specialList", "chanid", "sysCode",'offlineWhiteFlag','contactphone'],

    data() {
        return {
            viewIsReady: false,
            PB: {
                id: "",
                confid: "", //协议id
                starttime: "", //开始时间
                endtime: "", //结束时间
                channelid: "", //渠道id
                imgPath: "",
            },
            fileList: [],
            pdfList: [],
            picUrl: this.$baseURL + "/api/common/fileSingleUpLoad",
            // 验证规则
            rules: {
                confid: {
                    required: true,
                    message: "请选择特殊申请类型",
                    trigger: "blur",
                },
                starttime: {
                    required: true,
                    message: "请选择申请有效期开始日期",
                    trigger: "blur",
                },
                endtime: {
                    required: true,
                    message: "请选择申请有效期结束日期",
                    trigger: "blur",
                },
                imgPath: {
                    required: true,
                    message: "请上传特殊申请附件",
                    trigger: ["change", "blur"],
                },
            },
            loading: false,
            posting: false,
            needzip: {
                isZip: 1,
            },
        };
    },

    computed: {
        isUpdate() {
            return !!this.item?.id;
        },
        titleText() {
            return this.isUpdate ? "特殊申请" : "特殊申请";
        },
    },
    mounted() {
       
    },

    watch: {

        item(item, old) {
            if (item && item !== old) {
                if (!item.id) {
                    this.fileList = [];
                    this.pdfList = [];
                } else {
                    this.PB.id = item.id;
                    this.PB.confid = item.confid;
                    this.PB.starttime = item.starttime;
                    this.PB.endtime = item.endtime;
                    this.PB.channelid = item.channelid;


                    this.fileList = [];
                    this.pdfList = [];
                    if (item.path) {
                        let imgPathArr = item.path.split(",");
                        this.fileList = [];
                        this.pdfList = [];
                        imgPathArr.forEach((element) => {
                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );
                            if (suffix == "pdf") {
                                this.pdfList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            } else {
                                this.fileList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            }
                        });
                    }
                }

                for (let key in item) {
                    const _key = key.toCamel();
                    if (item[key] != null) this.PB[_key] = item[key];
                }
                this.viewIsReady = true;
                
            }
        },
    },

    methods: {
        handfile(item) {
            window.open(item.url);
        },
      
        // 删除图片
        delBackPic(index) {
            this.fileList.splice(index, 1);
        },
        // 删除图片
        delBackFile(index) {
            this.pdfList.splice(index, 1);
        },

        handleProgress(e, file) {
            this.posting = true;
        },
        handleBeforeUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf(".") + 1);
            const suffix = img === "jpg";
            const suffix2 = img === "png";
            const suffix3 = img === "jpeg";
            const suffix4 = img === "pdf";
            const isLt1M = file.size / 1024 / 1024 < 5;
            if (!suffix && !suffix2 && !suffix3 && !suffix4) {
                this.$message.error("只能上传图片或pdf附件！");
                return false;
            }
            // 可以限制图片的大小
            if (!isLt1M) {
                this.$message.error('上传图片大小不能超过 5MB!');
                return false
            }
            return suffix || suffix2 || suffix3 || suffix4;
        },
        // 上传文件成功
        handleBackImgSuccess(res, file) {
            this.posting = false;
            console.log(file);
            const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            if (suffix == "pdf") {
                const index = this.pdfList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.pdfList.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath,
                    });
                    console.log(this.pdfList);

                    let _imgPath = [];
                    this.pdfList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.PB.imgPath = _imgPath;
                } else {
                    this.pdfList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            } else {
                const index = this.fileList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.fileList.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath,
                    });
                    console.log(this.fileList);

                    let _imgPath = [];
                    this.fileList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.PB.imgPath = _imgPath;
                } else {
                    this.fileList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            }
        },
        handleSaveBefore() {
            if (this.posting)
                return this.$message({
                    message: "请等待上传完成",
                    type: "warning",
                });
            this.handleSave();
        },

        async handleSave() {
            console.log('可以保存');
            const loadingRef = this.$loading({
                target: ".special-edit .el-dialog",
            });

            try {
                let _imgPath = [];
                if (this.PB.signtype === "1" || this.PB.signtype === "2") {
                    this.fileList = [];
                    this.pdfList = [];
                } else {
                    this.fileList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.pdfList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.PB.imgPath = _imgPath;
                }
                await this.$refs.form.validate();

                const res = await this.$axios({
                    url: "/api/channel/saveSpecialFile",
                    method: "post",
                    data: {
                        // id: this.PB.id,
                        type: this.PB.confid, //协议id
                        applystarttime: this.PB.starttime, //开始时间
                        applyendtime: this.PB.endtime, //结束时间
                        chanId: this.chanid, //渠道id
                        file: _imgPath.join(),
                    },
                });

                if (res.code === 2000) {
                    loadingRef && loadingRef.close();
                     this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    this.$emit("change", res.data.id);
                    this.handleClose();
                    
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }
        },

        handleClose() {
            this.viewIsReady = false;
            this.PB = {
                id: "",
                confid: "", //协议id
                starttime: "", //开始时间
                endtime: "", //结束时间
                channelid: "", //渠道id
                imgPath: "",
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        },
    },
  
};
</script>

<style lang='scss' scoped>


/deep/ .el-dialog__body {
    padding: 0;
}
/deep/ .el-dialog__body {
    max-height: 80vh;
}
.private {
    padding: 16px;
}
.flexItem {
    display: flex;
    align-items: center;
}
.flexItem /deep/ .el-form-item__content {
    margin-left: 0 !important;
}
// 上传图片
.avatar-uploader /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader /deep/ .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}
.avatar {
    width: 80px;
    height: 80px;
    display: block;
}
//
.flex {
    display: flex;
}
.imgItem {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    position: relative;
}
.imgItem .img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
}
.imgItem .del {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 0px;
    color: #ccc;
    top: 2px;
    cursor: pointer;
}
.imgItem .del:hover {
    color: red;
}
.clomunform /deep/ .el-form-item__content {
    display: flex;
    flex-direction: column;
}
.c-pointer {
    cursor: pointer;
}
.a-center {
    align-items: center;
}

.filefrom /deep/ .el-icon-delete {
    background-color: rgba(12, 11, 11, 0.3);
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    border-top-right-radius: 6px;
}
</style>
<style>
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
.el-input__inner {
    line-height: 1px !important;
}
.el-select--medium .el-input__inner {
    line-height: 36px !important;
}
/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}



.special-edit ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
}

.filefrom .el-form-item__content {
    flex-wrap: wrap;
}
.filefrom .el-form-item__content .el-upload__tip {
    width: 100%;
    flex-shrink: 0;
    margin-top: 0;
    color: #d0021b;
}

</style>