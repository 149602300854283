<template>
    <div class="wrapper">
        <div class="channelWrap flex flex-column">
            <div class="header flex f16 a-center pl16 pr16">
                <div class="fb f16">当前位置：</div>
                <div class="color909399">渠道商信息管理</div>
            </div>
            <div class="flex a-center pl16 pr16 pb24">
                <div class="flex a-center tipsBox pr16">
                    <img style="margin-right:8px;" src="../../assets/images/info-circle.png" alt="">
                    <span class="hw_ml5">请认真填写渠道商信息（联系人姓名，电话号码，身份证号，开户行，账号名称，账户号码，地址），渠道商信息有误不能进行电子合同签署，账户信息有误将影响结佣结算。</span>
                </div>

            </div>
            <!-- <div>
                <div style="margin-bottom:5px;font-weight:600;">
                    1、检查主体协议及附件字段填写完整：乙方、身份证号、地址、协议有效期、乙方收款账户、甲方对接人信息、乙方的对接人信息、签署日期、对公协议乙方公章等
                </div>
                <div style="margin-bottom:5px;font-weight:600;">
                    2、检查主体协议及附件拍照完整上传系统
                </div>
                <div style="margin-bottom:5px;font-weight:600;">
                    3、检查协议字段与系统字段匹配：
                    <div style="font-weight:400;padding-left:24px;margin-top:3px;">
                        <div>
                            1)协议类型与签署协议版本匹配，
                        </div>
                        <div>
                            2)渠道名称、联系人姓名、身份证号、地址、手机号与协议内容一致，如遇无法调整联系总经办。
                        </div>
                        <div>
                            3)协议名称与签署协议版本对应
                        </div>
                        <div>
                            4)协议生效及结束日期与协议内容一致
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="content flex1 pl16 pr16">
                <el-form class="private" :model="PB" :rules="rules" label-width="160px" label-suffix=":" ref="form" @submit.prevent="handleSave">
                    <el-row class="">

                        <el-col :md="12" :lg="12">
                            <!-- this.sysCode -->
                            <el-form-item label="类型" prop="type" class="setform32">
                                <el-select v-model="PB.type" class="">
                                    <el-option v-for="e in typeList" :key="e.value" :label="e.name" :value="e.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="联系人真实姓名" prop="realname">
                                <el-input v-model.trim="PB.realname" class="" maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人身份证号" prop="idcard">
                                <el-input v-model.trim="PB.idcard" class="" maxlength="30"></el-input>
                            </el-form-item>
                             <el-form-item v-if="PB.type==2" label="法人真实姓名" prop='legalName' :rules="PB.type==2?rules.legalName:rules.none">
                                <el-input v-model.trim="PB.legalName" class="" maxlength="30"></el-input>
                            </el-form-item>
                            <el-form-item label="渠道商通信地址" prop="address">
                                <el-input v-model="PB.address" type="textarea" rows="3" class="" maxlength="100"></el-input>
                            </el-form-item>
                            <!-- PB.tradeids -->
                            <el-form-item label="渠道行业" prop="tradeids" class='cloumnform ' :class="isruleing&& (PB.tradeids=='')?'is-error':''">
                                <!-- {{PB.tradeids}} -->
                                <!-- {{fillDate}}
                                {{tradeShowArr}}
                                {{PB.tradeids}} -->
                                <span slot="label"><span style="color: #F56C6C;font-size: 14px;margin-right:4px;">*</span>渠道行业:</span>

                                <div style="display: flex;width:100%">
                                    <div style="display: flex;flex: 1;">
                                        <!-- <el-select v-if="!refresh" v-model="PB.type" class="">
                                            <el-option v-for="e in typeList" :key="e.value" :label="e.name" :value="e.value"></el-option>
                                        </el-select> -->
                                        <!-- v-if="refresh"  -->
                                        <el-cascader ref="cascader" v-if="refresh" :props="props" v-model="fillDate" @change='changetrade'></el-cascader>
                                    </div>
                                    <div v-show="tradeShowArr.length<3" style="margin-left:16px;cursor: pointer;color:#2878FF;" @click="addtradeid">添加</div>
                                </div>
                                <div :style="isruleing&& (PB.tradeids=='')?'display:block':'display:none;'" class="el-form-item__error">请选择渠道行业</div>
                                <div class="flex" style="flex-wrap: wrap;">
                                    <div v-for="(item,index) in tradeShowArr" :key="index" class="flex a-center" style="height: 24px;background: #E7E7E7;border-radius: 4px;margin-top: 8px;padding:0 12px;width: max-content;margin-right:12px;color:#323233;">
                                        <div>{{item.description}}</div>
                                        <i class="el-icon-close" style="color: #C3C3C3;cursor: pointer;margin-left:14px;" @click='delTrade(index)'></i>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="备注" prop="remark">
                                <el-input v-model="PB.remark" type="textarea" rows="3" class="" maxlength="100"></el-input>
                            </el-form-item>

                        </el-col>
                        <el-col :md="12" :lg="12">
                            <el-form-item label="渠道名称" prop="name">
                                <el-input v-model.trim="PB.name" class="" maxlength="100" @blur="nameBlur"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号码" prop="contactphone" class="phoneForm">
                                <el-input maxlength="11" type="number" v-if="showPhoneFlag=='1'" :disabled="editPhoneFlag=='0'" v-model.trim="PB.contactphone" class="" oninput="if(value.length>11)value=value.slice(0,11)"></el-input>

                                <el-input v-if="showPhoneFlag=='0'" disabled v-model.trim="contactphoneshow" class="" maxlength="20"></el-input>

                                <div v-if="showEPbtn|| (showPhoneFlag=='0' && editPhoneFlag=='1')" class="hover color2878FF ml12" @click='eidtPhone'>修改</div>

                                <div class="hover color2878FF ml12" @click="findByPhone">查询渠道详情</div>
                            </el-form-item>
                            <el-form-item label="对接员工" prop="empid">
                                <!-- <el-input v-model.trim="PB.empid" class="" maxlength="20"></el-input> -->
                                <template>
                                    <el-select v-model="PB.empid" filterable remote reserve-keyword placeholder="请输入" :remote-method="remoteMethod" :loading="loading">
                                        <el-option v-for="item in empOptions" :key="item.value" :label="item.label" :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-form-item>
                             <el-form-item v-if="PB.type==2" label="法人身份证号" prop='legalIdcard'>  
                                <el-input v-model.trim="PB.legalIdcard" class="" maxlength="30"></el-input>
                            </el-form-item>
                            <el-form-item label="公司电话" prop="commPhone">
                                <el-input v-model.trim="PB.commPhone" class="" maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item label="营业执照/身份证照" prop="imgPath" class="imgform">
                                <span slot="label"><span style="color: #f56c6c;font-size: 14px;"></span>营业执照/<br />身份证照:</span>
                                <div v-if="pdfList" class='flex'>
                                    <div @click="handfile(item)" class="imgItem" v-for="(item,index) in pdfList" :key="index">
                                        <img class="inlineblock img c-pointer " src="../../assets/images/pdfview.png">
                                        <i class="el-icon-delete del" @click.stop="delBackFile(index)"></i>
                                        <!-- <img class="del" @click.stop="delBackFile(index)" src="../../assets/images/close.jpg" alt=""> -->

                                    </div>
                                </div>
                                <viewer :images="fileList">
                                    <div class="flex">
                                        <div class="imgItem" v-for="(item,index) in fileList" :key="index">
                                            <img class="inlineblock img c-pointer " :src="item.url">
                                            <i class="el-icon-delete del" @click.stop="delBackPic(index)"></i>

                                            <!-- <img class="del" @click.stop="delBackPic(index)" src="../../assets/images/close.jpg" alt=""> -->
                                            <!-- <i class="el-icon-delete del" @click="delBackPic(index)"></i> -->
                                        </div>
                                    </div>
                                </viewer>
                                <el-upload :data='needzip' v-show="(fileList.length+pdfList.length)<=1" class="avatar-uploader" :action="picUrl" :show-file-list="false" accept=".jpg,.jpeg,.png,.pdf" :on-success="handleBackImgSuccess" :before-upload="handleBeforeUpload" :on-progress="handleProgress">
                                    <i class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="flex a-center addrow">
                    <div class="fb">协议信息：</div>
                    <el-button icon="el-icon-plus" type="primary" size="mini" @click.stop="handleAddBtn">新增</el-button>
                </div>
                <el-table :data="tableData" style="margin-bottom:50px;" :expand-row-keys="expandRowKeys"  :row-key="getRowKeys">
                    <el-table-column label="" prop="id" type="expand" width="70">
                        <template #header>
                            <div class="flex a-center hover" style="color:#666;font-weight: 400;justify-content: center;" @click="iClick()">
                                <span >{{allExpand?'收起':'展开'}} </span>
                                <i style="font-size:12px;" class="el-icon el-icon-arrow-right" :class="allExpand?'el-icon-arrow-down':''"></i>
                            </div>
                        </template>

                        <template slot-scope="props">
                            <div class="flex a-center" style="padding-left:20px;">
                                <div class="mr60"><span style="color:rgba(0, 0, 0, 1)">账户名称:</span><span style="color:rgba(0, 0, 0, 0.65)"> {{ props.row.accountName }}</span></div>
                                <div class="mr60"> <span style="color:rgba(0, 0, 0, 1)">开户支行名:</span><span style="color:rgba(0, 0, 0, 0.65)"> {{ props.row.bankName }}</span></div>
                                <div class="mr60"> <span style="color:rgba(0, 0, 0, 1)">账户号码:</span><span style="color:rgba(0, 0, 0, 0.65)"> {{ props.row.bankNum }}</span></div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="协议编号" prop="id" width="80">
                    </el-table-column>
                    <el-table-column label="协议名称" prop="treatyname"></el-table-column>
                    <el-table-column label="协议生效时间" prop="starttime"></el-table-column>
                    <el-table-column label="协议结束时间" prop="endtime"></el-table-column>
                    <el-table-column label="签约员工" prop="signempname" width="260"></el-table-column>

                    <el-table-column label="签署方式" prop="signType">
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.signTypeName}}
                            </div>
                            <!-- <div v-if="scope.row.signType==0">
                                线下签约
                            </div>
                            <div v-if="scope.row.signType==1">
                                线上签约
                            </div>
                            <div v-if="scope.row.signType==2">
                                申请协议打印签约
                            </div> -->
                        </template>
                    </el-table-column>
                    <el-table-column label="回传协议" prop="endtime">
                        <template slot-scope="scope">
                            <div v-if="scope.row.backhaulPath">
                                <div v-if="scope.row.pdfList2.length">
                                    <div @click="handPdf(item)" style="color:#2878FF;cursor: pointer;margin-top: 5px;" v-for="(item,index) in scope.row.pdfList2" :key="index">
                                        附件{{index+1}}
                                    </div>
                                </div>
                                <viewer :images="scope.row.fileList2" v-if="scope.row.fileList2.length">
                                    <div style="width:30px;height:30px;cursor: pointer;">
                                        <div style="color:#2878FF;cursor: pointer;position: absolute;margin-top: 5px;">预览</div>
                                        <div style="width:30px;height:30px;opacity: 0;position: absolute;" v-for="(item,index) in scope.row.fileList2" :key="index">
                                            <img style="width:30px;height:30px;" :src="item.url">
                                        </div>
                                    </div>
                                </viewer>

                            </div>

                            <div v-else></div>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column label="账户名称" prop="accountName"></el-table-column> -->
                    <!-- <el-table-column label="开户支行名" prop="bankName"></el-table-column> -->
                    <!-- <el-table-column label="账户号码" prop="bankNum"></el-table-column> -->
                    <el-table-column label="协议附件" prop="path" width="80">
                        <template slot-scope="scope">
                            <div v-if="scope.row.path">
                                <div v-if="scope.row.pdfList.length">
                                    <div @click="handPdf(item)" style="color:#2878FF;cursor: pointer;margin-top: 5px;" v-for="(item,index) in scope.row.pdfList" :key="index">
                                        附件{{index+1}}
                                    </div>
                                </div>
                                <viewer :images="scope.row.fileList" v-if="scope.row.fileList.length">
                                    <div style="width:30px;height:30px;cursor: pointer;">
                                        <div style="color:#2878FF;cursor: pointer;position: absolute;margin-top: 5px;">预览</div>
                                        <div style="width:30px;height:30px;opacity: 0;position: absolute;" v-for="(item,index) in scope.row.fileList" :key="index">
                                            <img style="width:30px;height:30px;" :src="item.url">
                                        </div>
                                    </div>
                                </viewer>

                            </div>

                            <div v-else>{{scope.row.contractStateName}}</div>
                        </template>

                    </el-table-column>
                    <el-table-column label="审核状态" prop="verifystatename" width="100">
                        <template slot-scope="scope">
                            <div class="flex a-center">
                                <div class="verifystate" :class="'verifystate'+scope.row.verifystate"></div> {{scope.row.verifystatename}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="240">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button v-if="scope.row.verifystate!=1 && (scope.row.signType==1?scope.row.contractState ==1?false:true:true) " type="text" @click.stop="handleEdit(scope.row)">编辑</el-button>
                                <el-button v-if="scope.row.signType==1 && chanCode" type="text" @click.stop="toPreviewPdf(scope.row)">发起电子协议签署</el-button>
                                <el-button v-if="scope.row.verifystate!=1" type="text" @click.stop="handleDelete(scope.row)">删除</el-button>

                                

                                <el-button v-if="scope.row.verifystate!=1 && PB.id && auth.audit && scope.row.path" type="text" @click.stop="handleAudit(scope.row,1)">确认无误</el-button>
                                <el-button v-if="scope.row.verifystate==1 && PB.id && auth.audit" type="text" @click.stop="handleAudit(scope.row,0)">取消审核</el-button>

                                <!-- // applyState>1  initPath    
// applyState=1 signState=1 stampState=0   || applyState=2 stampState=0 -->

                                <el-button v-if=" isEdit&&  scope.row.signType==2" type="text" @click.stop="downPreviewPdf(scope.row)">下载打印协议</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 特殊申请附件 -->
                <div class="flex a-center addrow" v-if="sysCode=='CRM'">
                    <div class="fb">特殊申请附件：</div>
                    <el-button icon="el-icon-plus" type="primary" size="mini" @click.stop="handleSpecialAddBtn">新增</el-button>

                    <el-button type="text" @click.stop="handleDownPath(1)">下载特殊活动申请模板（个人）</el-button>
                    <el-button type="text" @click.stop="handleDownPath(2)">下载特殊活动申请模板（公司）</el-button>
                </div>
                <el-table v-if="sysCode=='CRM'" :data="tableSpecialData" style="margin-bottom:50px;" :row-key="getRowKeys">
                    <el-table-column label="特殊申请类型" prop="typeName"></el-table-column>
                    <el-table-column label="申请有效期" prop="applyStartTimeName">
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.applyStartTimeName}} - {{scope.row.applyEndTimeName}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请附件" prop="file">
                        <template slot-scope="scope">
                            <div v-if="scope.row.file">
                                <div v-if="scope.row.pdfList.length">
                                    <div @click="handPdf(item)" style="color:#2878FF;cursor: pointer;margin-top: 5px;" v-for="(item,index) in scope.row.pdfList" :key="index">
                                        附件{{index+1}}
                                    </div>
                                </div>
                                <viewer :images="scope.row.fileList" v-if="scope.row.fileList.length">
                                    <div style="width:30px;height:30px;cursor: pointer;">
                                        <div style="color:#2878FF;cursor: pointer;position: absolute;margin-top: 5px;">预览</div>
                                        <div style="width:30px;height:30px;opacity: 0;position: absolute;" v-for="(item,index) in scope.row.fileList" :key="index">
                                            <img style="width:30px;height:30px;" :src="item.url">
                                        </div>
                                    </div>
                                </viewer>

                            </div>

                            <div v-else></div>
                        </template>
                    </el-table-column>
                     <el-table-column label="创建人" prop="createEmpName" width="260"></el-table-column>
                    <el-table-column label="创建时间" prop="createTimeName"></el-table-column>
                    <el-table-column label="操作" width="240">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button type="text" @click.stop="handleSpecialDelete(scope.row)">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 特殊申请附件 -->

            </div>
            <div class="foot-bar">
                <el-button @click="closeCurrentWindow">取消</el-button>
                <el-button @click="handleSave" type="primary">保存</el-button>
            </div>
        </div>
        <!-- 新增/编辑-->
        <ContractEdit :item.sync="editingItem" :offlineWhiteFlag='offlineWhiteFlag' :contactphone='PB.contactphone ' :sysCode='sysCode' :chanid="PB.id" :treatyList='treatyList' @change="fetchData"></ContractEdit>
        <!-- 特殊申请附件新增/编辑-->
        <specialApplication :item.sync="editingSpecialAddBtnItem" :offlineWhiteFlag='offlineWhiteFlag' :contactphone='PB.contactphone ' :sysCode='sysCode' :chanid="PB.id" :specialList='specialList' @change="fetchSpecialData"></specialApplication>

    </div>
</template>

<script>
import ContractEdit from "./ContractEdit.vue";
import specialApplication from "./specialApplication.vue";
import { base64encode, utf16to8, utf8to16, base64decode } from "../../utils/base64";

let id = 0;
export default {
    components: { ContractEdit,specialApplication },
    props: {},
    data() {
        return {
            specialids: [], //

            contractids: [], //新增渠道时每添加一个协议会返回一个id用开查询回显添加的协议
            treatyList: [], //协议名称
            configurationList_1: [], //渠道行业第一级
            configurationList_2: [], //渠道行业第二级
            trade_1: {},
            trade_2: {},
            tradeArr: [],
            tradeShowArr: [],

            //
            props: {
                lazy: true,
                lazyLoad: this.lazyLoad,
            },
            //
            picUrl: this.$baseURL + "/api/common/fileSingleUpLoad",
            // showPicUrl:this.$baseURL + "/api/common/fileSingleUpLoad",
            needzip: {
                isZip: 1,
            },

            showPicUrl: "",
            fileList: [],
            pdfList: [],
            empOptions: [],
            loading: false,

            PB: {
                type: "", //类型
                name: "", //渠道名称
                realname: "", //联系人真实姓名
                legalName:'',//法人真实姓名
                legalIdcard:'',//法人身份证号

                contactphone: "", //联系电话
                idcard: "", //身份证号
                empid: "", //对接员工
                address: "", //渠道商通信地址
                commPhone: "", //公司电话
                tradeids: "", //渠道行业
                remark: "", //备注
                imgPath: "",

                glhtmb: "",
                qysfzdgz: "1",
                beizhu: "",
                id: "",
                chanCode: "",
            },

            // 验证规则
            rules: {
                type: {
                    required: true,
                    message: "请选择类型",
                    trigger: "blur",
                },
                name: {
                    required: true,
                    message: "请输入渠道名称",
                    trigger: "blur",
                },
                realname: {
                    required: true,
                    message: "请输入联系人真实姓名",
                    trigger: "blur",
                },
                legalName: {
                    required: true,
                    message: "请输入法人真实姓名",
                    trigger: "blur",
                },
                contactphone: [
                    { required: true, message: "请输入手机号码" },
                    {
                        validator(r, v, c) {  //普通和澳洲号码验证
                            if (v === "") c();
                            else if (!/^1[3,4,5,6,7,8,9][0-9]{9}$/.test(v) && !/^(\+?61|0)4\d{8}$/.test(v)) {
                                c("请输入正确的手机号码格式");
                            }else c();
                        },
                    },
                    
                ],
                idcard: {
                    required: true,
                    message: "请输入身份证号",
                    trigger: "blur",
                },
                empid: {
                    required: true,
                    message: "请输入对接员工",
                    trigger: "blur",
                },
                address: {
                    required: true,
                    message: "请输入渠道商通信地址",
                    trigger: "blur",
                },
                none: {validator: function (r,v,c) {
                    c();
                }}
                // tradeids: {
                //     required: true,
                //     message: "请选择渠道行业",
                //     // trigger: "blur",
                //     // trigger:['change'],
                //     trigger: ["blur"],
                //     // trigger:[ "blur",'change'],
                // },
            },
            typeList: [
                { name: "个人", value: 1 },
                { name: "公司", value: 2 },
                { name: "集团资源", value: 3 },
            ],

            tableData: [],
            tableSpecialData: [],

            editingItem: null,
            editingSpecialAddBtnItem: null,

            urlQuery: "",
            chanCode: "", //渠道唯一编码
            isEdit: false, //判断是新增还是编辑
            sysCode: "", //AYM CEN
            channelInfoMap: "",
            contactphoneshow: "",
            editPhoneFlag: "1", //电话可以修改
            showPhoneFlag: "1", //电话可以看全
            showEPbtn: false,
            fillDate: "",
            refresh: true,
            isruleing: false,
            posting: false,
            auth: {
                audit: false,
            },
            expandRowKeys: [],
            expandRowSpecialKeys: [],

            offlineWhiteFlag:0, // 如果大于0 签署方式可以选线下财务申领签约
            specialList:[],//特殊申请类型
            allExpand:false,//全部展开
        };
    },
    created() {
        // console.log(this.getUrlQuery());
        // console.log(this.$router);
        // alert(this.getQueryString("id"));
        // this.$store.commit("setShowMenu", { showMenu: false });//隐藏菜单
        // 获取rul参数
        this.urlQuery = this.getUrlQuery();
        this.chanCode = this.urlQuery.chanCode;
        this.PB.chanCode = this.urlQuery.chanCode;
        if (this.chanCode) {
            this.isEdit = true;
            this.editPhoneFlag = this.urlQuery.editPhoneFlag + "";
            this.showPhoneFlag = this.urlQuery.showPhoneFlag + "";
        }
        this.sysCode = this.urlQuery.sysCode;
        this.offlineWhiteFlag = this.urlQuery.offlineWhiteFlag||0;

        // AYM不能添加分销商  其它能

        this.auth.audit = this.$hasAuthFor("/api/channel/updateVerifyState"); //
        // this.auth.downPreviewPdf = this.$hasAuthFor("/api/channel/downPreviewPdf"); //
        this.auth.gzPreviewPdf = this.$hasAuthFor("/api/channel/gzPreviewPdf"); //
        // this.auth.saveSpecialFile = this.$hasAuthFor("/api/channel/saveSpecialFile"); //
        // this.auth.deleteSpecialFile = this.$hasAuthFor("/api/channel/deleteSpecialFile"); //

        // this.auth.save = this.$hasAuthFor("api/configuration/addCom"); //新增、编辑
        // console.log(this.userInfo);
        if (!this.isEdit) {
            //新增 初始化对接员工
            this.empOptions = [
                {
                    name: this.userInfo.name,
                    phone: this.userInfo.phone,
                    usercode: this.userInfo.usercode,
                    value: this.userInfo.usercode,
                    label: this.userInfo.name + "--" + this.userInfo.phone,
                },
            ];
            this.PB.empid = this.userInfo.usercode;
        }
        // this.getConfigByType(); //获取一级渠道行业
        this.getQueryChannelInfo(); //获取详情以及新增时需要初始化的数据
        this.getConfigByType_3(); //获取特殊申请类型

    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        getRowKeys(row){
            return row.id
        },
        // 确认无误
        async handleAudit(data, state) {
            let loadingRef;
            try {
                if (state == 1) {
                    


                    await this.$confirm(`
                    <div style="line-height: initial;">
                        <div style="margin-bottom:5px;font-weight:600;">
                            1、检查主体协议及附件字段填写完整：
                            <span style="font-weight:400;">乙方、身份证号、地址、协议有效期、乙方收款账户、甲方对接人信息、乙方的对接人信息、签署日期、对公协议乙方公章等</span>
                            
                        </div>
                        <div style="margin-bottom:5px;font-weight:600;">
                            2、检查主体协议及附件拍照完整上传系统
                        </div>
                        <div style="margin-bottom:5px;font-weight:600;">
                            3、检查协议字段与系统字段匹配：
                            <div style="font-weight:400;padding-left:24px;margin-top:3px;">
                                <div>
                                    1)协议类型与签署协议版本匹配，
                                </div>
                                <div>
                                    2)渠道名称、联系人姓名、身份证号、地址、手机号与协议内容一致，如遇无法调整联系总经办。
                                </div>
                                <div>
                                    3)协议名称与签署协议版本对应
                                </div>
                                <div>
                                    4)协议生效及结束日期与协议内容一致
                                </div>
                            </div>
                        </div>
                    </div>
                    `, "财务审核协议" ,{
          dangerouslyUseHTMLString: true,
           confirmButtonText: '确认无误' //
        });
                } else {
                    await this.$confirm("确定取消审核吗？", "系统提示");
                }
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channel/updateVerifyState",
                    method: "post",
                    data: {
                        id: parseInt(data.id),
                        verifystate: state,
                    },
                });
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.getTableData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },

        handPdf(item) {
            window.open(item.url);
        },
        handleProgress(e, file) {
            this.posting = true;
        },

        // 获取url参数
        getUrlQuery() {
            // 本地调试 注意域名要一样 不需要urlStr解码 不用启移民

            // let url =
            //     "http://192.168.110.136:8020/#/customer/channelPhoneList/add?id=475&chanCode=CH-AYM284&sysCode=AYM&editPhoneFlag=1&showPhoneFlag=0&offlineWhiteFlag=1";
            let url = window.location.href;
            let urlStrDe = url.split("?")[1];
            // let urlStr = urlStrDe;  //本地用上面url调试用
            let urlStr = base64decode(urlStrDe); //打包用

            // console.log(url);
            // let aaa = base64encode(url)
            // console.log(aaa);
            // let bbb = base64decode('c3lzQ29kZT1BWU0=')
            // console.log(bbb);

            // 创建空对象存储参数
            let obj = {};
            // 再通过 & 将每一个参数单独分割出来
            let paramsArr = urlStr.split("&");
            for (let i = 0, len = paramsArr.length; i < len; i++) {
                // 再通过 = 将每一个参数分割为 key:value 的形式
                let arr = paramsArr[i].split("=");
                obj[arr[0]] = arr[1];
            }
            return obj;
        },
        changetrade(data) {
            console.log(data);
            console.log(this.$refs["cascader"].getCheckedNodes());
            this.trade_1 = this.configurationList_1.find((item) => {
                return item.id == data[0];
            });
            this.trade_2 = this.configurationList_2.find((item) => {
                return item.id == data[1];
            });
        },

        async lazyLoad(node, resolve) {
            console.log(node);
            const { level } = node;
            if (node.level === 0) {
                const res = await this.getConfigByType();
                resolve(res);
            }
            if (node.level === 1) {
                const res = await this.getConfigByType_2(node.value);
                resolve(res);
            }
        },
        // 获取一级渠道行业
        async getConfigByType() {
            const res = await this.$axios({
                url: "/api/configuration/queryConfigByType",
                method: "post",
                data: {
                    type: 2, //字典类型 1协议 2行业
                    pid: 0, //父级id
                },
            });
            if (res.code === 2000) {
                res.data.configurationList.map((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    item.leaf = "";
                });
                this.configurationList_1 = res.data.configurationList || [];
                // this.configurationList_1 = [{id:2,name:'aaa'},{id:4,name:'bbb'}]
                return this.configurationList_1;
            }
        },
        // 获取二级渠道行业
        async getConfigByType_2(tradeid_1) {
            const res = await this.$axios({
                url: "/api/configuration/queryConfigByType",
                method: "post",
                data: {
                    type: 2, //字典类型 1协议 2行业
                    pid: tradeid_1, //父级id
                },
            });
            if (res.code === 2000) {
                res.data.configurationList.map((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    item.leaf = 1;
                });
                this.configurationList_2 = res.data.configurationList || [];
                // this.configurationList_2 = [{id:2,name:'aaa'},{id:4,name:'bbb'}]
                return this.configurationList_2;
            }
        },
        // 获取特殊申请类型
        async getConfigByType_3() {
            const res = await this.$axios({
                url: "/api/configuration/queryConfigByType",
                method: "post",
                data: {
                    type: 3, //字典类型 1协议 2行业
                    pid: 0, //父级id
                },
            });
            if (res.code === 2000) {
                res.data.configurationList.map((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    item.leaf = "";
                });
                this.specialList = res.data.configurationList || [];
            }
        },

        // 获取详情以及新增时需要初始化的数据
        async getQueryChannelInfo() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/channel/queryChannelInfo",
                method: "post",
                data: {
                    chanCode: this.chanCode,
                    // id:'',
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.treatyList = res.data.treatyList || []; //协议名称
                if (this.chanCode) {
                    //回显

                    this.channelInfoMap = res.data.channelInfoMap || "";
                    this.PB.type = this.channelInfoMap.type;

                    if (this.PB.type == 4) {
                        this.typeList = [{ name: "分销商", value: 4 }];
                    } else {
                        this.typeList = [
                            { name: "个人", value: 1 },
                            { name: "公司", value: 2 },
                            { name: "集团资源", value: 3 },
                        ];
                    }

                    this.PB.name = this.channelInfoMap.name;
                    this.PB.realname = this.channelInfoMap.realName;
                    this.PB.legalName = this.channelInfoMap.legalName;
                    this.PB.legalIdcard = this.channelInfoMap.legalIdcard;
                    this.PB.contactphone = this.channelInfoMap.phone;
                    this.PB.idcard = this.channelInfoMap.idcard;
                    this.PB.empid = this.channelInfoMap.connEmpCode;
                    this.PB.address = this.channelInfoMap.address;
                    this.PB.commPhone = this.channelInfoMap.commPhone;
                    this.PB.tradeids = this.channelInfoMap.tradeids;
                    this.PB.remark = this.channelInfoMap.remark;
                    // this.PB.imgPath = this.channelInfoMap.imgPath
                    this.PB.imgPath = "";
                    this.PB.id = this.channelInfoMap.id;
                    this.empOptions = [
                        {
                            value: this.channelInfoMap.connEmpCode,
                            label: this.channelInfoMap.empName,
                        },
                    ];

                    this.tradeShowArr =
                        this.channelInfoMap.childchannelTradelist || [];
                    this.fileList = [];
                    this.pdfList = [];
                    if (this.channelInfoMap.imgPath) {
                        let imgPathArr = this.channelInfoMap.imgPath.split(",");
                        this.fileList = [];
                        this.pdfList = [];
                        imgPathArr.forEach((element) => {
                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );
                            if (suffix == "pdf") {
                                this.pdfList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            } else {
                                this.fileList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            }
                        });
                    }
                    // this.channelInfoMap.phone= '13111447788'
                    if (this.showPhoneFlag === "0") {
                        this.contactphoneshow =
                            this.channelInfoMap.phone.replace(
                                /(\d{3})\d{4}(\d{4})/,
                                "$1****$2"
                            );
                    }

                    this.getTableData();
                    this.getSpecialTableData();

                }
            } else {
                this.$message({
                    message: res.msg || "查询异常，请稍后再试",
                    type: "warning",
                });
            }
        },
        eidtPhone() {
            this.showEPbtn = true;
            this.contactphoneshow = "";
            this.PB.contactphone = "";
            this.showPhoneFlag = "1";
            this.editPhoneFlag = "1";
        },

        delTrade(index) {
            this.tradeShowArr.splice(index, 1);
            // this.tradeShowArr=[]
            let idarr = [];
            this.tradeShowArr.forEach((element) => {
                idarr.push(element.id);
            });
            this.PB.tradeids = idarr.join();

            // this.tradeArr.splice(index,1)
            // this.tradeShowArr=[]
            // let idarr = []
            // this.tradeArr.forEach(element => {
            //     idarr.push(element[1].id)
            //     this.tradeShowArr.push({showName:element[0].name+'--'+element[1].name,id:element[1].id})
            // });
            // this.PB.tradeids = idarr.join()
        },

        addtradeid(data) {
            console.log('data',data);
            console.log(this.tradeShowArr);
            console.log(this.trade_2);

            if(this.tradeShowArr.findIndex(item=>{
                return item.id == this.trade_2.id
            })>-1){
                this.$message({
                    message: "不能重复添加渠道行业",
                    type: "warning",
                });
                return;
            }

            if (this.trade_2.id) {
                this.isruleing = false;
                this.tradeShowArr.push({
                    description: this.trade_1.name + "--" + this.trade_2.name,
                    id: this.trade_2.id,
                });
                this.trade_1 = {};
                this.trade_2 = {};
                this.configurationList_2 = [];

                let idarr = [];
                this.tradeShowArr.forEach((element) => {
                    idarr.push(element.id);
                });
                this.PB.tradeids = idarr.join();
                this.fillDate = [];

                this.refresh = false;
                setTimeout(() => {
                    this.refresh = true;
                }, 20);
            }
        },

        // 搜索对接员工
        async remoteMethod(query) {
            console.log(query);
            if (query !== "") {
                this.loading = true;
                const res = await this.$axios({
                    url: "/api/employee/queryEmpLike",
                    method: "post",
                    data: {
                        searchValue: query,
                        sysCode: this.sysCode,
                    },
                });
                if (res.code === 2000) {
                    this.loading = false;
                    res.data.empList.map((item) => {
                        item.label = item.name + "--" + item.phone;
                        item.value = item.usercode;
                    });
                    this.empOptions = res.data.empList;
                }
            } else {
                this.empOptions = [];
            }
        },
        nameBlur() {
            console.log(this.PB.name);
            if (this.PB.type == 1) {
                this.PB.realname = this.PB.name;
            }
        },
        // 查询渠道详情
        async findByPhone() {
            if (!this.PB.contactphone) {
                this.$message({
                    message: "请输入手机号码",
                    type: "warning",
                });
            } else {
                const loading = this.$loading();
                try {
                    const res = await this.$axios({
                        url: "/api/channel/checkChannelInfo",
                        method: "post",
                        data: {
                            phone: this.PB.contactphone,
                        },
                    });

                    if (res.code === 2000) {
                        this.PB.idcard = res.data.accountMap.idcard || "";
                        loading.close();
                    } else if (res.code !== 1003) throw res;
                } catch (reason) {
                    // console.warn("查询异常，请稍后再试", reason);
                    this.$message({
                        message: reason.msg || "查询异常，请稍后再试",
                        type: "warning",
                    });
                    loading.close();
                }
            }
        },

        handleBeforeUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf(".") + 1);
            const suffix = img === "jpg";
            const suffix2 = img === "png";
            const suffix3 = img === "jpeg";
            const suffix4 = img === "pdf";
            // const isLt1M = file.size / 1024 / 1024 < 1;
            if (!suffix && !suffix2 && !suffix3 && !suffix4) {
                this.$message.error("只能上传图片或pdf附件！");
                return false;
            }
            // 可以限制图片的大小
            // if (!isLt1M) {
            //     this.$message.error('上传图片大小不能超过 1MB!');
            // }
            return suffix || suffix2 || suffix3 || suffix4;
        },
        // 上传文件成功
        handleBackImgSuccess(res, file) {
            console.log(res);
            console.log(file);
            this.posting = false;

            const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            if (suffix == "pdf") {
                const index = this.pdfList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.pdfList.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath,
                    });
                    console.log(this.pdfList);
                } else {
                    this.pdfList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            } else {
                const index = this.fileList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.fileList.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath,
                    });
                    console.log(this.fileList);
                } else {
                    this.fileList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            }
        },
        // 删除图片
        delBackPic(index) {
            this.fileList.splice(index, 1);
        },
        // 删除图片
        delBackFile(index) {
            this.pdfList.splice(index, 1);
        },
        handfile(item) {
            window.open(item.url);
        },

        fetchData(data) {
            if (data) {
                this.contractids.push(data);
            }
            this.getTableData();
        },
        fetchSpecialData(data) {
            if (data) {
                this.specialids.push(data);
            }
            this.getSpecialTableData();
        },
        
        // 查询协议列表
        async getTableData() {
            let _data = {};
            if (this.PB.id) {
                _data = {
                    channelid: this.PB.id,
                };
            } else {
                _data = {
                    ids: this.contractids.join(),
                };
            }
            const res = await this.$axios({
                url: "/api/channel/queryChannelTreaty",
                method: "post",
                data: _data,
            });
            if (res.code === 2000) {
                console.log("this.PB.id", this.PB.id);
                this.tableData = res.data.channelTreatyList || [];
                // this.expandRowKeys = this.tableData.map(row => row.id);
                this.tableData.forEach((item) => {
                    item.contractStateName = "";
                    if (this.PB.id) {
                        if (item.contractState) {
                            if (item.signType == 2) {
                                item.contractStateName = "纸质合同签署中";
                            } else {
                                item.contractStateName = "电子协议签署中";
                            }
                        }
                    }
                    item.fileList = [];
                    item.pdfList = [];

                    item.fileList2 = [];
                    item.pdfList2 = [];

                    // 老数据 有pdf数据
                    if (item.path) {
                        let imgPathArr = item.path.split(",");

                        imgPathArr.forEach((element) => {
                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );

                            let urlnew = "";
                            if (element.includes("http")) {
                                urlnew = element;
                            } else {
                                urlnew = this.$baseURL + "/" + element;
                            }
                            if (suffix == "pdf") {
                                item.pdfList.unshift({
                                    name: "",
                                    url: urlnew,
                                    path: element,
                                });
                            } else {
                                item.fileList.unshift({
                                    name: "",
                                    url: urlnew,
                                    path: element,
                                });
                            }
                        });
                    }

                    if (item.backhaulPath) {
                        let imgPathArr = item.backhaulPath.split(",");
                        imgPathArr.forEach((element) => {
                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );
                            if (suffix == "pdf") {
                                item.pdfList2.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            } else {
                                item.fileList2.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            }
                        });
                    }
                });
            }
        },
        async getSpecialTableData() {
            let _data = {};
            if (this.PB.id) {
                _data = {
                    chanId: this.PB.id,
                };
            } else {
                _data = {
                    ids: this.specialids.join(),
                };
            }
            const res = await this.$axios({
                url: "/api/channel/querySpecialFile",
                method: "post",
                data: _data,
                // data:{
                //     chanId:this.PB.id
                // }
            });
            if (res.code === 2000) {
                console.log("this.PB.id", this.PB.id);
                this.tableSpecialData = res.data.specialFileList || [];
                this.expandRowSpecialKeys = this.tableSpecialData.map(row => row.id);
                this.tableSpecialData.forEach((item) => {
                    item.fileList = [];
                    item.pdfList = [];
                    
                    if (item.file) {
                        let imgPathArr = item.file.split(",");
                        imgPathArr.forEach((element) => {
                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );
                            if (suffix == "pdf") {
                                item.pdfList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            } else {
                                item.fileList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            }
                        });
                    }
                });
            }
        },
        async handleAddBtn() {
            // 渠道为新增时
            if (!this.PB.id) {
                let _cannot = this.tableData.findIndex((item) => {
                    return item.signType == 1 && !item.path;
                });
                if (_cannot >= 0) {
                    this.$message({
                        message: "协议签署中，不能发起合同签署",
                        type: "warning",
                    });
                    return;
                }
                this.editingItem = this.initialPB();
            } else {
                const loading = this.$loading();
                const checkres = await this.$axios({
                    url: "/api/channelContract/checkChannelContract",
                    method: "post",
                    data: {
                        id: this.PB.id,
                    },
                });
                loading.close();
                if (checkres.code === 2000) {
                    if (checkres.data.treatyId > 0) {
                        //大于0 代表有待发起的线上签署的协议
                        this.$confirm(
                            "您有一份线上签署协议流程未完成，不能新增协议",
                            "提示信息",
                            {
                                confirmButtonText: "发起电子协议签署",
                                cancelButtonText: "取消",
                            }
                        )
                            .then(() => {
                                if (checkres.data.tempCount == 0) {
                                    this.$alert(
                                        " 此合同未找到合同模板，请采用线下签约",
                                        "提示信息",
                                        {
                                            confirmButtonText: "取消",
                                            callback: (action) => {
                                                // this.closeCurrentWindow();
                                            },
                                        }
                                    );
                                } else {
                                    this.saveToPreviewPdf(checkres.data.treatyId)
                                }
                            })
                            .catch(() => {
                                console.log("取消");
                            });
                    } else if (checkres.data.count > 0) {
                        //大于0代表待完成签署的电子协议
                        this.$alert(
                            " 您有一份线上签署协议流程未完成，不能新增协议",
                            "提示信息",
                            {
                                confirmButtonText: "确定关闭",
                                callback: (action) => {
                                    console.log("关闭");
                                },
                            }
                        );
                    } else {
                        this.editingItem = this.initialPB();
                    }
                } else {
                    loading.close();
                    this.$message({
                        message: checkres.msg || "系统异常",
                        type: "error",
                    });
                }
            }
        },
        async handleSpecialAddBtn() {
            this.editingSpecialAddBtnItem = this.initialSPB();
        },
        initialSPB() {
            return {
                leixing: "",
                fileimingcheng: "",
                zidianbianma: "",
                mingcheng: "",
                paixu: 99,
                beizhu: "",
                id: "",
            };
        },
        initialPB() {
            return {
                leixing: "",
                fileimingcheng: "",
                zidianbianma: "",
                mingcheng: "",
                paixu: 99,
                beizhu: "",
                id: "",
            };
        },
        closeCurrentWindow() {
            window.close();
        },
        async handleSave() {
            if(this.tradeShowArr.length<3){ //如果没有3个默认添加上去
                this.addtradeid({from:'save'});
            }
            if (this.posting)
                return this.$message({
                    message: "请等待上传完成",
                    type: "warning",
                });
            const loading = this.$loading();
            try {
                this.isruleing = true;
                await this.$refs.form.validate();
                if (this.PB.tradeids == "") {
                    //单独验证
                    loading.close();
                    return;
                }

                // 校验电话号码是否重复
                const res = await this.$axios({
                    url: "/api/channel/checkChannelPhone",
                    method: "post",
                    data: {
                        phone: this.PB.contactphone,
                        id: this.PB.id,
                    },
                });
                if (res.code === 2000) {
                    if (res.data.count > 0) {
                        //重复
                        this.$message({
                            message: "联系电话重复",
                            type: "warning",
                        });
                        loading.close();
                    } else {
                        let _data = {
                            realname: this.PB.realname,
                            legalName: this.PB.legalName,
                            legalIdcard: this.PB.legalIdcard,
                            name: this.PB.name,
                            phone: this.PB.contactphone,
                            idcard: this.PB.idcard,
                            type: this.PB.type,
                            address: this.PB.address,
                            tradeids: this.PB.tradeids, //渠道行业
                            commPhone: this.PB.commPhone,
                            imgPath: "",
                            remark: this.PB.remark,
                            connEmpCode: this.PB.empid,
                            systemCode: this.sysCode,
                            channelTreatyids: "", //新增时协议的id 有协议时必传
                            id: this.PB.id,
                            channelSpecialFileids:'',//特殊附件的id
                        };
                        // let idarr = []
                        // this.tradeArr.forEach(element => {
                        //     idarr.push(element[1].id)
                        // });
                        // this.PB.tradeids = this.idarr.join()

                        _data.channelTreatyids = this.contractids.join();
                        _data.channelSpecialFileids = this.specialids.join();

                        let _imgPath = [];
                        this.fileList.forEach((ele) => {
                            _imgPath.push(ele.path);
                        });
                        this.pdfList.forEach((ele) => {
                            _imgPath.push(ele.path);
                        });
                        _data.imgPath = _imgPath.join();
                        const saveres = await this.$axios({
                            url: "/api/channel/saveChannel",
                            method: "post",
                            data: _data,
                        });
                        if (saveres.code === 2000) {
                            this.trade_1 = {};
                            this.trade_2 = {};
                            this.fillDate = [];

                            // loading.close();
                            // 保存成功

                            const checkres = await this.$axios({
                                url: "/api/channelContract/checkChannelContract",
                                method: "post",
                                data: {
                                    id: saveres.data.channelId,
                                },
                            });
                            loading.close();
                            if (checkres.code === 2000) {


                                if (checkres.data.treatyId > 0) {
                                    //大于0 代表有待发起的线上签署的协议
                                    if (checkres.data.type == 2) {
                                        this.$confirm(
                                            "渠道商信息保存成功",
                                            "提示信息",
                                            {
                                                confirmButtonText:
                                                    "打印渠道协议",
                                                cancelButtonText: "确定关闭",
                                            }
                                        )
                                            .then(() => {
                                                if (
                                                    checkres.data.tempCount == 0
                                                ) {
                                                    this.$alert(
                                                        " 此合同未找到合同模板，请采用线下签约",
                                                        "提示信息",
                                                        {
                                                            confirmButtonText:
                                                                "确定关闭",
                                                            callback: (
                                                                action
                                                            ) => {
                                                                this.closeCurrentWindow();
                                                            },
                                                        }
                                                    );
                                                } else {
                                                    this.saveToPreviewPdf(checkres.data.treatyId)
                                                }
                                            })
                                            .catch(() => {
                                                console.log("取消");
                                                this.closeCurrentWindow();
                                            });
                                    } else {
                                        this.$confirm(
                                            "渠道商信息保存成功",
                                            "提示信息",
                                            {
                                                confirmButtonText:
                                                    "发起电子协议签署",
                                                cancelButtonText: "确定关闭",
                                            }
                                        )
                                            .then(() => {
                                                if (
                                                    checkres.data.tempCount == 0
                                                ) {
                                                    this.$alert(
                                                        " 此合同未找到合同模板，请采用线下签约",
                                                        "提示信息",
                                                        {
                                                            confirmButtonText:
                                                                "确定关闭",
                                                            callback: (
                                                                action
                                                            ) => {
                                                                this.closeCurrentWindow();
                                                            },
                                                        }
                                                    );
                                                } else {
                                                    this.saveToPreviewPdf(checkres.data.treatyId)
                                                }
                                            })
                                            .catch(() => {
                                                console.log("取消");
                                                this.closeCurrentWindow();
                                            });
                                    }
                                } else if (checkres.data.count > 0) {
                                    console.log(123);
                                    //大于0代表待完成签署的电子协议
                                    if (checkres.data.type == 2) {
                                        this.$alert(
                                            " 渠道商有待完成签署的协议，请及时跟进。",
                                            {
                                                confirmButtonText: "确定关闭",
                                                callback: (action) => {
                                                    this.closeCurrentWindow();
                                                },
                                            }
                                        );
                                    } else {
                                        this.$alert(
                                            " 渠道商有待完成签署的电子协议，请及时跟进签署情况，进入渠道列表----查看在线签署协议",
                                            "提示信息",
                                            {
                                                confirmButtonText: "确定关闭",
                                                callback: (action) => {
                                                    this.closeCurrentWindow();
                                                },
                                            }
                                        );
                                    }
                                } else {
                                    this.$alert(
                                        "渠道商信息保存成功",
                                        "提示信息",
                                        {
                                            confirmButtonText: "确定关闭",
                                            callback: (action) => {
                                                this.closeCurrentWindow();
                                            },
                                        }
                                    );
                                }
                            } else {
                                loading.close();
                                this.$message({
                                    message: checkres.msg || "系统异常",
                                    type: "error",
                                });
                            }

                            // this.$alert("保存成功", "系统提示", {
                            //     confirmButtonText: "确定",
                            //     callback: (action) => {
                            //         this.closeCurrentWindow();
                            //     },
                            // });
                        } else if (saveres.code !== 1003) throw saveres;
                    }
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loading.close();
            }
        },
        handleEdit(data) {
            this.editingItem = data;
        },
        async toPreviewPdf(data) {
            if (data.signType == 1 && data.contractState == 1) {
                this.$message({
                    message: "协议签署中，不能发起合同签署",
                    type: "warning",
                });
                return;
            } else {
                const loading = this.$loading();
                const checkres = await this.$axios({
                    url: "/api/channelContract/checkChannelContract",
                    method: "post",
                    data: {
                        id: this.PB.id,
                    },
                });
                loading.close();
                if (checkres.code === 2000) {
                    if (checkres.data.tempCount == 0) {
                        this.$alert(
                            " 此合同未找到合同模板，请采用线下签约",
                            "提示信息",
                            {
                                confirmButtonText: "取消",
                                callback: (action) => {
                                    // this.closeCurrentWindow();
                                },
                            }
                        );
                    } else {
                        this.saveToPreviewPdf(checkres.data.treatyId)
                    }
                } else {
                    loading.close();
                    this.$message({
                        message: checkres.msg || "系统异常",
                        type: "error",
                    });
                }
            }
        },
        
        downSpecialPdf(downurl,filePath,type) {
            // 下载
            let url = downurl;
            // 使用 XMLHttpRequest 或 fetch 下载文件
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";

            xhr.onload = function () {
                if (xhr.status === 200) {
                    // 创建 Blob 对象
                    let blob = xhr.response;

                    // 创建下载链接
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = filePath.split('/').pop(); // 设置下载文件的名称
                    if(type==1){
                        a.download = '特殊活动申请模板（个人）.pdf'; // 设置下载文件的名称
                    }
                    if(type==2){
                        a.download = '特殊活动申请模板（公司）.pdf'; // 设置下载文件的名称
                    }
                    // 添加到文档中并触发点击
                    document.body.appendChild(a);
                    a.click();

                    // 移除元素
                    document.body.removeChild(a);
                }
            };
            xhr.send();
            this.$message({
                message: "下载成功",
                type: "success",
            });
        },

        
                    
        // pdf直接下载
        goBirthDownPdf(downurl,filePath) {
            // 下载
            let url = downurl;
            // 使用 XMLHttpRequest 或 fetch 下载文件
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";

            xhr.onload = function () {
                if (xhr.status === 200) {
                    // 创建 Blob 对象
                    let blob = xhr.response;

                    // 创建下载链接
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = filePath.split('/').pop(); // 设置下载文件的名称
                    // 添加到文档中并触发点击
                    document.body.appendChild(a);
                    a.click();

                    // 移除元素
                    document.body.removeChild(a);
                }
            };
            xhr.send();
            this.$message({
                message: "下载成功",
                type: "success",
            });
        },
        // 保存成功后打印渠道协议
        async saveToPreviewPdf(treayId) {
            const loading = this.$loading();
            const checkres = await this.$axios({
                url: "/api/channel/checkDownTreayInfo",
                method: "post",
                data: {
                    treayId: treayId,
                },
            });
            loading.close();
            console.log(checkres);
            // return
            if (checkres.code === 2000) {
                this.$router.push({
                    path: `/customer/channelPhoneList/previewPdf`,
                    query: {
                        id: treayId,
                        sysCode:
                            this.sysCode,
                    },
                });
            } else {
                loading.close();
                this.$message({
                    message: checkres.msg || "系统异常",
                    type: "error",
                });
            }
        },

        async downPreviewPdf(data) {
            if (data.applyState == 0) {
                const loading = this.$loading();
                const checkres = await this.$axios({
                    url: "/api/channel/checkDownTreayInfo",
                    method: "post",
                    data: {
                        treayId: data.id,
                    },
                });
                loading.close();
                console.log(checkres);
                // return
                if (checkres.code === 2000) {
                    const url = new URL(location.href);
                    url.hash = `/customer/channelPhoneList/previewPdf?id=${data.id}&sysCode=${this.sysCode}`;
                    window.open(url);
                } else {
                    loading.close();
                    this.$message({
                        message: checkres.msg || "系统异常",
                        type: "error",
                    });
                }
            } else {
                const applyState = data.applyState;
                const stampState = data.stampState;
                if (applyState == 1 || (applyState == 2 && stampState == 1)) {
                    const loading = this.$loading();
                    await this.$axios({
                        url: "/api/channel/saveDownTreatyLog",
                        method: "post",
                        data: {
                            treayId: data.id,
                            channelId: this.PB.id,
                        },
                    });
                    loading.close();
                    // window.open(this.$baseURL + "/" + data.winkPath);
                    this.goBirthDownPdf(this.$baseURL + "/" + data.winkPath,data.winkPath)
                } else {
                    this.$message({
                        message: "盖章协议需要财务审核通过后方可下载打印",
                        type: "warning",
                    });
                }
            }
        },
        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示");
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channel/deleteChannelTreaty",
                    method: "post",
                    data: {
                        id: parseInt(data.id),
                    },
                });
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.getTableData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        async handleSpecialDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示");
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channel/deleteSpecialFile",
                    method: "post",
                    data: {
                        id: parseInt(data.id),
                    },
                });
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.getSpecialTableData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 下载特殊活动申请模板
        async handleDownPath(type) {
            const loading = this.$loading();
            const res =  await this.$axios({
                url: "/api/channel/downSpecialFileTemp",
                method: "post",
                data: {
                    type,
                },
            });
            loading.close();
            if(res.data.url){
                this.downSpecialPdf(res.data.url,res.data.url,type)
            }
        },
        iClick(){
            let _this = this
            
            _this.allExpand = !_this.allExpand
            console.log(_this.allExpand);
            if(_this.expandRowKeys.length>0){
                _this.expandRowKeys = []
            }else{
                _this.expandRowKeys = _this.tableData.map(row => row.id);
            }
            console.log('_this.expandRowKeys',_this.expandRowKeys);
        }
        
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    height: 100%;
    padding: 80px;
    background: #f9f9f9;
}
@media (max-width: 1920px) {
    .wrapper {
        padding: 80px;
    }
}
// 小于1600时
@media (max-width: 1600px) {
    .wrapper {
        padding: 40px;
    }
}

.channelWrap {
    background: #fff;
    height: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    // min-width: 1200px;
    // padding:0 16px;
}
.header {
    height: 62px;
}
// 公用
.pl16 {
    padding-left: 16px;
}
.pr16 {
    padding-right: 16px;
}
.pt24 {
    padding-top: 24px;
}
.pb24 {
    padding-bottom: 24px;
}
.ml12 {
    margin-left: 12px;
}
.mr60 {
    margin-right: 60px;
}
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex1 {
    flex: 1;
}
.a-center {
    align-items: center;
}
.fb {
    font-weight: 600;
}
.f16 {
    font-size: 16px;
}
.hover {
    cursor: pointer;
}
// 公用完
.color2878FF {
    color: #2878ff;
}
.color909399 {
    color: #909399;
}
.tipsBox {
    height: 40px;
    background: rgba(40, 120, 255, 0.1);
    color: #2878ff;
    border-radius: 2px;
    padding-left: 12px;
    width: 100%;
    line-height: 16px;
}
.foot-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    z-index: 9;
    height: 56px;
    // padding-left: 16px;
    // padding-right: 16px;
    background-color: white;
    box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
}
.content {
    overflow: auto;
}

.el-select {
    width: 100%;
}
.addrow {
    padding-top: 16px;
}
.phoneForm .el-input {
    flex: 1;
}
// 上传图片
.avatar-uploader /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader /deep/ .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}
.avatar {
    width: 80px;
    height: 80px;
    display: block;
}
//
.imgItem {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    position: relative;
}
.imgItem .img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    border-top-right-radius: 10px;
}
.imgItem .del {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -8px;
    color: #ccc;
    top: -8px;
    cursor: pointer;
}
// .imgItem .del:hover {
//     color: red;
// }
.cloumnform /deep/ .el-form-item__content {
    display: flex;
    flex-direction: column;
}
.imgform /deep/ .el-form-item__label {
    line-height: normal;
}

.verifystate {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 4px;
    background: #e02020;
}
.verifystate0 {
    background: #e02020;
}
.verifystate1 {
    background: #6dd400;
}
.setform32 /deep/ .el-form-item__content {
    height: 32px;
}
/deep/ .el-cascader {
    width: 100%;
}
.c-pointer {
    cursor: pointer;
}
/deep/ .el-table__expanded-cell {
    background: #f4f7fa;
}
/deep/ .el-table__expanded-cell:hover {
    background: #f4f7fa !important;
}

.imgItem .del {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 0px;
    color: #ccc;
    top: 2px;
    cursor: pointer;
}
.imgItem .del:hover {
    color: red;
}
/deep/ .el-icon-arrow-down{
    transition: transform 0.2s ease-in-out;
    transform: rotate(90deg);
}
</style>