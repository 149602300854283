<template>
    <el-dialog class="contract-edit" :visible="viewIsReady" @close="handleClose" :title="titleText" width="730px">

        <!-- <div class="flex a-center pl16 pr16">
            <div class="flex a-center tipsBox pr16">
                <img style="margin-right:8px;" src="../../assets/images/info-circle.png" alt="">
                <span ref="cmdlist" class="hw_ml5">请认真核对渠道商信息（联系人姓名，电话号码，开户行，账号名称，账户号码），渠道商信息有误不能进行电子合同签署，账户信息有误将影响结佣。</span>
            </div>
        </div> -->
        <div id="box" ref="box">
            <div class="marquee-box flex a-center" ref="marquee">

                <p ref="cmdlist" id="pWidth">
                    <img style="margin-right:8px;width:16px;height:16px;margin-left:12px;" src="../../assets/images/info-circle.png" alt="">
                    请认真核对渠道商信息（联系人姓名，电话号码，开户行，账号名称，账户号码），渠道商信息有误不能进行电子合同签署，账户信息有误将影响结佣。
                </p>
            </div>
        </div>

        <el-form class="private" :model="PB" :rules="rules" label-width="150px" label-suffix=":" ref="form" @submit.prevent="handleSaveBefore">
            <el-form-item label="协议名称" prop="confid">
                <el-select v-model="PB.confid" class="width3">
                    <el-option :class="e.viewState===0?'treatyselectccc':''" v-for="e in treatyList" :key="e.id" :label="e.name" :value="e.id"></el-option>
                </el-select>
                <el-button style="margin-left:16px;" v-show="downPath" @click="handleDownPath" type="primary" size="mini">下载协议模板合同</el-button>

                
            </el-form-item>
            <el-form-item label="协议开始日期" prop="starttime">
                <el-date-picker @change="chooseStime" class="width3" v-model="PB.starttime" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="协议结束日期" prop="endtime">
                <el-date-picker class="width3" v-model="PB.endtime" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="签约员工" prop="signempid">
                <!-- <el-input v-model.trim="PB.signempid"
                    class="width3" maxlength="20"></el-input> -->
                <template>
                    <el-select class="width3" v-model="PB.signempid" filterable remote reserve-keyword placeholder="请输入" :remote-method="remoteMethod" :loading="loading">
                        <el-option v-for="item in empOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>

            </el-form-item>

            <el-form-item label="开户支行名" prop="bankname" class="clomunform">
                <el-input v-model.trim="PB.bankname" class="width3" maxlength="50"></el-input>
                <div style="font-size:12px;height: 21px;line-height: 33px;padding-left: 15px;">XX银行XX市XX支行/XX分行</div>
            </el-form-item>
            <el-form-item label="账户名称" prop="accountname">
                <el-input v-model.trim="PB.accountname" class="width3" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="账户号码" prop="banknum">
                <el-input v-model.trim="PB.banknum" type="number" class="width3" oninput="if(value.length>30)value=value.slice(0,30)"></el-input>
            </el-form-item>
            <el-form-item label="签署方式" prop="signtype" class="flexItem">
                <el-radio v-model="PB.signtype" label="1" v-show="showsign1">线上签约</el-radio>
                <el-radio v-model="PB.signtype" label="2" v-show="showsign2">申请协议打印签约</el-radio>
                <el-radio v-model="PB.signtype" label="0" v-show="showsign0">线下财务申领签约</el-radio>

            </el-form-item>
            <el-form-item label="附件" prop="imgPath" v-if="PB.signtype==='0'" class="filefrom">
                <div v-if="pdfList" class='flex'>
                    <div @click="handfile(item)" class="imgItem" v-for="(item,index) in pdfList" :key="index">
                        <img class="inlineblock img c-pointer " src="../../assets/images/pdfview.png">
                        <i class="el-icon-delete del" @click.stop="delBackFile(index)"></i>
                    </div>
                </div>
                <viewer :images="fileList">
                    <div class="flex">
                        <div class="imgItem" v-for="(item,index) in fileList" :key="index">
                            <img class="inlineblock img c-pointer " :src="item.url">
                            <i class="el-icon-delete del" @click="delBackPic(index)"></i>
                        </div>
                    </div>
                </viewer>
                <el-upload :data='needzip' v-show="(fileList.length+pdfList.length)<=4" class="avatar-uploader" :action="picUrl" :show-file-list="false" accept=".jpg,.jpeg,.png,.pdf" :on-success="handleBackImgSuccess" :before-upload="handleBeforeUpload" :on-progress="handleProgress">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="el-upload__tip">附件类型：图片或pdf,大小小于5M，最多只能上传5个附件</div>

            </el-form-item>
            <!-- <el-form-item label="" prop="">
                <div style="color:#E02020;">请前往渠道列表点击“在线签署协议”生成电子合同发起签署</div>
            </el-form-item> -->
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSaveBefore" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "MenuEdit",
    components: {},
    props: ["item", "treatyList", "chanid", "sysCode",'offlineWhiteFlag','contactphone'],

    data() {
        return {
            scrollvalue: -600,
            diaWidth: 700,
            viewIsReady: false,
            PB: {
                id: "",
                confid: "", //协议id
                starttime: "", //开始时间
                endtime: "", //结束时间
                signempid: "", //签约员工id
                channelid: "", //渠道id
                signtype: "1", //签约类型 1线上 0线下
                bankname: "", //开户支行名
                accountname: "", //账户名称
                banknum: "", //账户号码
                imgPath: "",
            },
            fileList: [],
            pdfList: [],
            picUrl: this.$baseURL + "/api/common/fileSingleUpLoad",
            // 验证规则
            rules: {
                confid: {
                    required: true,
                    message: "请选择协议名称",
                    trigger: "blur",
                },
                starttime: {
                    required: true,
                    message: "请选择开始日期",
                    trigger: "blur",
                },
                endtime: {
                    required: true,
                    message: "请选择结束日期",
                    trigger: "blur",
                },
                signempid: {
                    required: true,
                    message: "请选择签约员工",
                    trigger: "blur",
                },
                imgPath: {
                    required: true,
                    message: "请上传附件",
                    trigger: ["change", "blur"],
                },
            },
            empOptions: [],
            loading: false,
            timer: null,
            posting: false,
            needzip: {
                isZip: 1,
            },
            confidIsDisabled:false,// 所选的协议名称是否禁用的

            showsign1:true, //线上签约
            showsign2:true, //申请协议打印签约
            showsign0:true, //线下财务申领签约

            downPath:'',

            
        };
    },

    computed: {
        isUpdate() {
            return !!this.item?.id;
        },
        titleText() {
            return this.isUpdate ? "编辑协议" : "新增协议";
        },
    },
    mounted() {
        console.log(this.$refs.cmdlist);
        // this.timer = setInterval(this.clickCommend, 10);
    },

    watch: {
        scrollvalue(newValue, oldValue) {
            let allWidth =
                this.diaWidth + document.getElementById("pWidth").offsetWidth;
            if (newValue <= -allWidth) {
                this.scrollvalue = 0;
            }
        },
        'PB.confid'(newValue, oldValue){
            console.log(newValue,oldValue);
            this.previewPdfByConfId()
            let obj = this.treatyList.find(element => {
                return element.id == newValue
            });
            console.log(obj);
            if(obj){
                if(obj.value==302){ //2023版渠道合作协议（公司版）---申请协议打印签约
                    this.showsign1 = false
                    this.showsign2 = true
                    this.showsign0 = false
                    if(!this.PB.id){
                        this.PB.signtype='2'
                    }
                    if(this.offlineWhiteFlag>0){
                        this.showsign0 = true
                    }

                } else if(obj.value==301){ //2023版渠道合作协议（个人版）---申请协议打印签约、线下财务申领签约、电子签
                    this.showsign1 = true
                    this.showsign2 = true
                    this.showsign0 = true
                    if(!this.PB.id){
                        this.PB.signtype='1'
                    }

                }else if(obj.value==128){ //2019版房产渠道个人合作协议---线下财务申领签约
                    this.showsign1 = false
                    this.showsign2 = false
                    this.showsign0 = true
                    this.PB.signtype='0'
                }else if(obj.value==125){ //移民渠道合作协议---线下财务申领签约
                    this.showsign1 = false
                    this.showsign2 = false
                    this.showsign0 = true
                    this.PB.signtype='0'
                }else if(obj.value==288){ //移民渠道协议（市场经理版）---申请协议打印签约
                    this.showsign1 = false
                    this.showsign2 = true
                    this.showsign0 = false
                    if(!this.PB.id){
                        this.PB.signtype='2'
                    }
                    if(this.offlineWhiteFlag>0){
                        this.showsign0 = true
                    }
                }else{// 其它的协议：签署方式均为线下财务申领签约上传附件信息
                    this.showsign1 = false
                    this.showsign2 = false
                    this.showsign0 = true
                    this.PB.signtype='0'
                }

                // 禁用的
                if(obj.viewState===0){
                     this.showsign1 = false
                    this.showsign2 = false
                    this.showsign0 = true
                    this.PB.signtype='0'
                    this.confidIsDisabled = true
                }else{
                    this.confidIsDisabled = false
                }

            }else{
                this.confidIsDisabled = false
                // this.PB.signtype='1'
            }
            



        },

        item(item, old) {
            if (item && item !== old) {
                if (!item.id) {
                    this.initemp();
                    this.fileList = [];
                    this.pdfList = [];
                } else {
                    this.PB.id = item.id;
                    this.PB.confid = item.confid;
                    this.PB.starttime = item.starttime;
                    this.PB.endtime = item.endtime;
                    this.PB.signempid = item.signempid;
                    this.PB.channelid = item.channelid;
                    this.PB.bankname = item.bankName;
                    this.PB.accountname = item.accountName;
                    this.PB.banknum = item.bankNum;
                    this.PB.signtype = item.signType + "";

                    this.empOptions = [
                        {
                            value: item.signempid,
                            label: item.signempname,
                        },
                    ];

                    this.fileList = [];
                    this.pdfList = [];
                    if (item.path) {
                        let imgPathArr = item.path.split(",");
                        this.fileList = [];
                        this.pdfList = [];
                        imgPathArr.forEach((element) => {
                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );
                            if (suffix == "pdf") {
                                this.pdfList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            } else {
                                this.fileList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            }
                            // this.fileList.push({
                            //         name: '',
                            //         url: this.$baseURL + "/" + element,
                            //         path: element
                            //     }
                            // )
                        });
                    }
                }

                for (let key in item) {
                    const _key = key.toCamel();
                    if (item[key] != null) this.PB[_key] = item[key];
                }
                this.viewIsReady = true;
                setTimeout(() => {
                    this.$refs.cmdlist.style.left = 0 + "px";
                    this.timer = setInterval(this.clickCommend, 20);
                }, 10);

                if (!item.id) {
                    this.initemp();
                }
            }
        },
    },

    methods: {
        handfile(item) {
            window.open(item.url);
        },
        clickCommend(e) {
            let _this = this;
            this.$nextTick(() => {
                this.scrollvalue -= 1;
                if (this.$refs.cmdlist) {
                    this.$refs.cmdlist.style.left =
                        _this.diaWidth + this.scrollvalue + "px";
                }
            });
        },

        chooseStime(data) {
            this.PB.endtime = this.getDateTime(data, "date", 2);
        },
        //  两年后前一天
        getDateTime(date = new Date(), type = "dateTime", afterNum) {
            if (date === "") return "";
            else {
                date = new Date(date);
            }
            date.setFullYear(date.getFullYear() + afterNum); //几年后
            let dateparse = Date.parse(date);
            let newdateparse = dateparse - 3600 * 24 * 1000; //减一天
            date = new Date(newdateparse);
            var seperator1 = "-",
                seperator2 = ":",
                year = date.getFullYear(),
                month = date.getMonth() + 1,
                strDate = date.getDate(),
                hour = date.getHours(),
                minute = date.getMinutes(),
                second = date.getSeconds();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minute >= 0 && minute <= 9) {
                minute = "0" + minute;
            }
            if (second >= 0 && second <= 9) {
                second = "0" + second;
            }
            var theDate;
            if (type === "year") {
                theDate = year;
            } else if (type === "dateTime") {
                theDate =
                    year +
                    seperator1 +
                    month +
                    seperator1 +
                    strDate +
                    " " +
                    hour +
                    seperator2 +
                    minute +
                    seperator2 +
                    second;
            } else if (type === "date") {
                theDate = year + seperator1 + month + seperator1 + strDate;
            } else if (type === "month") {
                theDate = year + seperator1 + month;
            } else if (type === "monthDate") {
                theDate =
                    year +
                    seperator1 +
                    month +
                    seperator1 +
                    strDate +
                    " " +
                    hour +
                    seperator2 +
                    minute;
            }
            console.log("theDate" + theDate);
            return theDate;
        },

        initemp() {
            // console.log(this.userInfo);
            // 初始化对接员工
            this.empOptions = [
                {
                    name: this.userInfo.name,
                    phone: this.userInfo.phone,
                    value: this.userInfo.employeeId,
                    label: this.userInfo.name + "--" + this.userInfo.phone,
                },
            ];
            this.PB.signempid = this.userInfo.employeeId;
            this.showsign1 = true
            this.showsign2 = true
            this.showsign0 = true
        },
         async previewPdfByConfId() {
            if(this.PB.confid){
                const loadingRef = this.$loading({
                    target: ".contract-edit .el-dialog",
                });
                const res = await this.$axios({
                    url: "/api/channelContract/previewPdfByConfId",
                    method: "post",
                    data: {
                        confId: this.PB.confid,
                    },
                });
                    loadingRef && loadingRef.close();
    
                if (res.code === 2000) {
                    this.downPath = res.data.path
                }else{
                    this.downPath = ''
                }
            }else{
                this.downPath = ''
            }
        },
        handleDownPath(){
            window.open(this.downPath, "download");
        },

        // 搜索对接员工
        async remoteMethod(query) {
            console.log(query);
            if (query !== "") {
                this.loading = true;
                const res = await this.$axios({
                    url: "/api/employee/queryEmpLike",
                    method: "post",
                    data: {
                        searchValue: query,
                        sysCode: this.sysCode,
                    },
                });
                if (res.code === 2000) {
                    this.loading = false;
                    res.data.empList.map((item) => {
                        item.label = item.name + "--" + item.phone;
                        item.value = item.id;
                    });
                    this.empOptions = res.data.empList;
                }
            } else {
                this.empOptions = [];
            }
        },
        // 删除图片
        delBackPic(index) {
            this.fileList.splice(index, 1);
        },
        // 删除图片
        delBackFile(index) {
            this.pdfList.splice(index, 1);
        },

        handleProgress(e, file) {
            this.posting = true;
        },
        handleBeforeUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf(".") + 1);
            const suffix = img === "jpg";
            const suffix2 = img === "png";
            const suffix3 = img === "jpeg";
            const suffix4 = img === "pdf";
            // const isLt1M = file.size / 1024 / 1024 < 1;
            if (!suffix && !suffix2 && !suffix3 && !suffix4) {
                this.$message.error("只能上传图片或pdf附件！");
                return false;
            }
            // 可以限制图片的大小
            // if (!isLt1M) {
            //     this.$message.error('上传图片大小不能超过 1MB!');
            // }
            return suffix || suffix2 || suffix3 || suffix4;
        },
        // 上传文件成功
        handleBackImgSuccess(res, file) {
            this.posting = false;
            console.log(file);
            const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            if (suffix == "pdf") {
                const index = this.pdfList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.pdfList.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath,
                    });
                    console.log(this.pdfList);

                    let _imgPath = [];
                    this.pdfList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.PB.imgPath = _imgPath;
                } else {
                    this.pdfList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            } else {
                const index = this.fileList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.fileList.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath,
                    });
                    console.log(this.fileList);

                    let _imgPath = [];
                    this.fileList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.PB.imgPath = _imgPath;
                } else {
                    this.fileList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            }
        },
        handleSaveBefore() {
            const pattern = /^(\+?61|0)4\d{8}$/;
            // 如果是澳洲的手机号，并且签署方式为上线签约则给提示  不让保存
            if (pattern.test(this.contactphone) && this.PB.signtype=='1') { 
                return this.$message({
                    message: "澳洲手机号不能进行协议在线签署。",
                    type: "warning",
                });
            }
            if (this.posting)
                return this.$message({
                    message: "请等待上传完成",
                    type: "warning",
                });

            if (this.PB.id && this.PB.signtype === "2") {
                this.$confirm(
                    "当前协议已进入签约流程，重新保存协议将清除已上传或归档的附件等信息，协议将重新进入签约流程，是否确定保存？",
                    "提示信息",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                    .then(() => {
                        this.handleSave();
                    })
                    .catch(() => {
                        console.log("取消");
                    });
            } else {
                this.handleSave();
            }
        },

        async handleSave() {
            const loadingRef = this.$loading({
                target: ".contract-edit .el-dialog",
            });

            try {
                let _imgPath = [];
                if (this.PB.signtype === "1" || this.PB.signtype === "2") {
                    this.fileList = [];
                    this.pdfList = [];
                } else {
                    this.fileList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.pdfList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.PB.imgPath = _imgPath;
                }
                await this.$refs.form.validate();

                const res = await this.$axios({
                    url: "/api/channel/saveChannelTreaty",
                    method: "post",
                    data: {
                        id: this.PB.id,
                        confid: this.PB.confid, //协议id
                        starttime: this.PB.starttime, //开始时间
                        endtime: this.PB.endtime, //结束时间
                        signempid: this.PB.signempid, //签约员工id
                        channelid: this.chanid, //渠道id
                        signtype: this.PB.signtype, //签约类型 1线上 0线下
                        bankname: this.PB.bankname, //开户支行名
                        accountname: this.PB.accountname, //账户名称
                        banknum: this.PB.banknum, //账户号码
                        path: _imgPath.join(),
                    },
                });

                if (res.code === 2000) {
                    loadingRef && loadingRef.close();
                    if(this.PB.signtype=='1'){
                        this.$alert('请生成合同发起合同签署', '保存成功', {
                            confirmButtonText: '确定',
                            callback: () => {
                                this.$emit("change", res.data.id);
                                this.handleClose();
                            }
                        });
                    }else if(this.PB.signtype=='2'){
                        this.$alert('请下载打印协议', '保存成功', {
                            confirmButtonText: '确定',
                            callback: () => {
                                this.$emit("change", res.data.id);
                                this.handleClose();
                            }
                        });
                    }else{
                        this.$message({
                            message: "保存成功",
                            type: "success",
                        });
                        this.$emit("change", res.data.id);
                        this.handleClose();
                    }
                    
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }
        },

        handleClose() {
            clearInterval(this.timer);
            this.viewIsReady = false;
            this.scrollvalue = -600;
            this.PB = {
                id: "",
                confid: "", //协议id
                starttime: "", //开始时间
                endtime: "", //结束时间
                signempid: "", //签约员工id
                channelid: "", //渠道id
                signtype: "1", //签约类型 1线上 0线下
                bankname: "", //开户支行名
                accountname: "", //账户名称
                banknum: "", //账户号码
                imgPath: "",
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style lang='scss' scoped>
.hint {
    color: orangered !important;
}
.tipsBox {
    height: 40px;
    background: #e9f1ff;
    color: #2878ff;
    border-radius: 2px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    line-height: 18px;
    display: flex;
    align-items: center;
}
/deep/ .el-dialog__body {
    padding: 0;
}
/deep/ .el-dialog__body {
    max-height: 80vh;
}
.private {
    padding: 16px;
}
.flexItem {
    display: flex;
    align-items: center;
}
.flexItem /deep/ .el-form-item__content {
    margin-left: 0 !important;
}
// 上传图片
.avatar-uploader /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader /deep/ .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}
.avatar {
    width: 80px;
    height: 80px;
    display: block;
}
//
.flex {
    display: flex;
}
.imgItem {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    position: relative;
}
.imgItem .img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
}
.imgItem .del {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 0px;
    color: #ccc;
    top: 2px;
    cursor: pointer;
}
.imgItem .del:hover {
    color: red;
}
.clomunform /deep/ .el-form-item__content {
    display: flex;
    flex-direction: column;
}
.c-pointer {
    cursor: pointer;
}
.a-center {
    align-items: center;
}

.filefrom /deep/ .el-icon-delete {
    background-color: rgba(12, 11, 11, 0.3);
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    border-top-right-radius: 6px;
}
</style>
<style>
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
.el-input__inner {
    line-height: 1px !important;
}
.el-select--medium .el-input__inner {
    line-height: 36px !important;
}
/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

.marquee-box {
    position: relative;
    width: 100%;
    height: 40px;
    overflow: auto;
    background: #e9f1ff;
}

#pWidth {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: absolute; /*  作了修改*/
    top: 0; /*  作了修改*/
    left: 0px; /* 作了修改*/
    line-height: 40px;
    display: block;
    word-break: keep-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #2878ff;
    display: flex;
    align-items: center;
}
.contract-edit ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
}
.marquee-box {
    scrollbar-width: none;
}
.filefrom .el-form-item__content {
    flex-wrap: wrap;
}
.filefrom .el-form-item__content .el-upload__tip {
    width: 100%;
    flex-shrink: 0;
    margin-top: 0;
    color: #d0021b;
}
.treatyselectccc{
    /* background: #efefef ; */
    color: #d5d5d5;
}
</style>